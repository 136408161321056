




















import {Component, Vue} from 'vue-property-decorator';
import TrackTableList from '@/components/track/TrackTableList.vue';
import UPagination from '@/ui-lib/pagination/Pagination.vue';
import UBlock from '@/ui-lib/block/Block.vue';
import {TrackService} from '@/api';
import {Route} from 'vue-router';
import {TrackListItem} from '@/types';
import {TableField} from '@/ui-lib/table/Table.vue';

const limit = 30;

const getStart = (route: Route) => typeof route.query.start === 'string' ? parseInt(route.query.start, 10) : 0;
const getSortField = (route: Route): 'id' | 'likes' | 'dislikes' | 'listeners_per_month' | 'skips_per_month' => {
  const val = route.query?.sortField?.toString();

  switch (val) {
    case 'likes':
    case 'dislikes':
    case 'listeners_per_month':
    case 'skips_per_month':
      return val;
    default:
      return 'id';
  }
};

const getSortOrder = (route: Route): 'asc' | 'desc' => {
  const val = route.query?.sortOrder?.toString();
  switch (val) {
    case 'asc':
      return val;
    default:
      return 'desc';
  }
};

@Component<TrackListView>({
  components: {
    UPagination,
    UBlock,
    TrackTableList,
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const {list, total} = await TrackService.list({
        start: getStart(to),
        limit: limit,
        order_column: getSortField(to),
        order_dir: getSortOrder(to),
      });

      next((vm) => {
        vm.tracks = list;
        vm.total = total;
      });
    } catch (e) {
      next(e);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.updateList(to);

      next();
    } catch (e) {
      next(e);
    }
  },

  metaInfo() {
    return {
      title: 'Все треки',
    };
  }
})
export default class TrackListView extends Vue {
  private total = 0;
  private tracks: TrackListItem[] = [];
  private tableFields: TableField[] = [
    {
      name: 'select',
      label: '',
      width: '52px',
    },

    {
      name: 'play-pause',
      label: '',
      width: '54px',
    },

    {
      name: 'id',
      label: 'ID',
      sortable: true,
      width: '65px',
    },

    {
      name: 'title',
      label: 'Название',
    },

    {
      name: 'likes',
      label: 'Лайки',
      sortable: true,
      width: '85px',
      align: 'right',
    },

    {
      name: 'dislikes',
      label: 'Дизлайки',
      sortable: true,
      width: '110px',
      align: 'right',
    },

    {
      name: 'listeners_per_month',
      label: 'Слуш/мес',
      sortable: true,
      width: '115px',
      align: 'right',
    },

    {
      name: 'skips_per_month',
      label: 'Скип/мес',
      sortable: true,
      width: '115px',
      align: 'right',
    },

    {
      name: 'compilations',
      label: 'Подборки',
      width: '250px',
    },

    {
      name: 'playlists',
      label: 'Плейлисты',
      width: '250px',
    },
  ];

  private get limit() {
    return limit;
  }

  private get start() {
    return getStart(this.$route);
  }

  private changeStart(newStart: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        start: newStart.toString(),
      },
    });
  }

  private async updateList(route: Route) {
    const {list, total} = await TrackService.list({
      start: getStart(route),
      limit: limit,
      order_column: getSortField(route),
      order_dir: getSortOrder(route),
    });

    this.tracks = list;
    this.total = total;
  }
}
